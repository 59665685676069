.accordion {
  list-style: none;
}

.accordion-arrow {
  border: solid #5fa744;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  margin-left: 5px;
  transition-duration: 0.5s;
  transition-property: transform;
}

.arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.arrow-up {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
}

.summary_wrapper {
  overflow-x: hidden;
  transition: height ease 0.3s;
  -webkit-transition: height ease 0.3s;
  -moz-transition: height ease 0.3s;
  -ms-transition: height ease 0.3s;
  -o-transition: height ease 0.3s;
}
